var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center justify-space-between",
            staticStyle: { width: "100%" }
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                ? _c(
                    "span",
                    { staticClass: "primary--text font-weight-bold" },
                    [_vm._v("Course Users")]
                  )
                : _vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE
                ? _c(
                    "span",
                    { staticClass: "primary--text font-weight-bold" },
                    [_vm._v("Project Users")]
                  )
                : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
                ? _c(
                    "span",
                    { staticClass: "primary--text font-weight-bold" },
                    [_vm._v("Dataset Users")]
                  )
                : _vm._e(),
              _vm.tab === _vm.tabValues.SPACE_MEMBERS_TAB &&
              _vm.fetchingSpaceUsers === true
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Fetching users data .....")
                  ])
                : _vm._e(),
              _vm.tab === _vm.tabValues.SPACE_INSTANCES_TAB &&
              _vm.fetchingInstances === true
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Fetching instances data .....")
                  ])
                : _vm._e(),
              _vm.tab === _vm.tabValues.SPACE_INVITATIONS_TAB &&
              _vm.fetchingSpaceInvitations === true
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Fetching invitations data .....")
                  ])
                : _vm._e()
            ])
          ]
        )
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            {
              staticClass: "mt-5",
              attrs: {
                "background-color": "rgb(96, 96, 96,0.05)",
                color: "primary"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "v-tab",
                  { key: tab.tab },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v(_vm._s(tab.icon))]
                    ),
                    _vm._v(" " + _vm._s(tab.tabName) + " ")
                  ],
                  1
                )
              }),
              _c("v-spacer"),
              _c(
                "v-tab",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "secondary", small: "" }
                    },
                    [_vm._v("add")]
                  ),
                  _c(
                    "span",
                    { staticClass: "secondary--text font-weight-bold" },
                    [_vm._v("invite")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-7",
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "v-tab-item",
                  { key: tab.tabName },
                  [
                    _c(tab.component, {
                      tag: "component",
                      on: {
                        instanceData: function($event) {
                          return _vm.setDataForExistingInstanceToInviteTo(
                            $event.data
                          )
                        },
                        goToInvite: function($event) {
                          return _vm.switchTab($event.value, "invite")
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              _c(
                "v-tab-item",
                [
                  _c("TheSpaceInvite", {
                    tag: "component",
                    attrs: {
                      dataForInstanceToInviteTo: _vm.dataForInstanceToInviteTo
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }