import { render, staticRenderFns } from "./TheSpaceUserManagement.vue?vue&type=template&id=7cf16064&"
import script from "./TheSpaceUserManagement.vue?vue&type=script&lang=js&"
export * from "./TheSpaceUserManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VSpacer,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cf16064')) {
      api.createRecord('7cf16064', component.options)
    } else {
      api.reload('7cf16064', component.options)
    }
    module.hot.accept("./TheSpaceUserManagement.vue?vue&type=template&id=7cf16064&", function () {
      api.rerender('7cf16064', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/space/views/TheSpaceUserManagement.vue"
export default component.exports